import { InputText } from '../../UI/InputText';
import { Card } from '../Card';
import { Lots } from '../Lots';

export const Block = ({ title, onRemove, description, lots, language, currency, isDownloading, setTitle, setDescription, updateLot, onRemoveLot, onAddLot }) => {
    return (
        <div className='block'>
            <h2 className={'blockTitle'}>
                <InputText
                    text={isDownloading}
                    multiline
                    setValue={(e) => setTitle(e.target.value)}
                    value={title}
                />
            </h2>
            <div className="blockDescription">
                <InputText text={isDownloading} setValue={(e) => setDescription(e.target.value)} value={description} multiline={true} />
            </div>
            <Card>
                <Lots
                    language={language}
                    currency={currency}
                    text={isDownloading}
                    lots={lots}
                    updateLot={updateLot}
                    onRemoveLot={(id) => {
                        onRemoveLot(id);
                    }}
                    onAddLot={onAddLot}
                />
            </Card>

            {isDownloading ? null : (
                <span onClick={onRemove} className={'removeBlock'}>
                    {language == 'ru' ? 'Удалить блок' : 'Remove the block'}
                </span>
            )}
        </div>
    )
};